import { Link } from "gatsby";
import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { StaticImage } from "gatsby-plugin-image";
import { Button } from "react-bootstrap";

const Navigation = () => {
	return (
		<Navbar fixed="top" bg="primary" expand="lg" variant="dark">
			<Navbar.Brand as={Link} to="/" className="text-white">
				<StaticImage
					src="../images/logo.svg"
					style={{ height: "auto", width: "75px" }}
				/>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="text-light hind-light mx-auto">
					<NavDropdown
						title="Services"
						id="collasible-nav-dropdown"
						className=""
					>
						<NavDropdown.Item as={Link} to="/">
							Business Coaching
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/executive-coaching">
							Executive Coaching
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/leadership-coaching">
							Leadership Coaching
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/management-coaching-london">
							Management Coaching London
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/coach-for-entrepreneurs">
							Coach for Entrepreneurs
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/business-mentoring">
							Business Mentoring
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/business-mot-health-check">
							Business MOT/Health Check
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/problem-buster-package">
							Problem Buster Package
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/business-coaching-new-york">
							Business Coaching New York
						</NavDropdown.Item>
					</NavDropdown>
					<NavDropdown
						title="Industries"
						id="collasible-nav-dropdown"
						className=""
					>
						<NavDropdown.Item as={Link} to="/industries/doctors">
							Doctors
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/industries/surgeons">
							Surgeons
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/industries/aesthetic-doctors">
							Aesthetic Doctors
						</NavDropdown.Item>
						<NavDropdown.Item
							as={Link}
							to="/industries/teachers-and-headteachers"
						>
							Teachers & Headteachers
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/industries/coaches">
							Coaches
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/industries/trades">
							Trades
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/industries/legal-professionals">
							Legal Professionals
						</NavDropdown.Item>
						<NavDropdown.Item
							as={Link}
							to="/industries/accountants-and-tax-advisors"
						>
							Accountants and Tax Advisors
						</NavDropdown.Item>
						<NavDropdown.Item
							as={Link}
							to="/industries/web-designers-and-digital-marketers"
						>
							Web Designers and Digital Marketers
						</NavDropdown.Item>
						<NavDropdown.Item
							as={Link}
							to="/industries/architects-and-building-professionals"
						>
							Architects and Building Professionals
						</NavDropdown.Item>
						<NavDropdown.Item
							as={Link}
							to="/industries/graphic-designers-branding-consultants-and-printers"
						>
							Graphic Designers, Branding Consultants and Printers
						</NavDropdown.Item>
						<NavDropdown.Item
							as={Link}
							to="/industries/photographers-film-and-video-makers"
						>
							Photographers, Film and Video Makers
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/industries/creatives">
							Creatives
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/industries/interior-designers">
							Interior Designers
						</NavDropdown.Item>
					</NavDropdown>
					<Nav.Link as={Link} to="/about" className="text-white">
						About
					</Nav.Link>
					<Nav.Link as={Link} to="/packages" className="text-white">
						Packages
					</Nav.Link>
					<Nav.Link as={Link} to="/blog" className="text-white">
						Blog
					</Nav.Link>
					<NavDropdown
						title="Testimonials"
						id="collasible-nav-dropdown"
						className=""
					>
						<NavDropdown.Item as={Link} to="/written-testimonials">
							Written Testimonials
						</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/video-testimonials">
							Video Testimonials
						</NavDropdown.Item>
					</NavDropdown>

					<Nav.Link
						as={Link}
						to="/frequently-asked-questions"
						className="text-white"
					>
						FAQs
					</Nav.Link>
					<Nav.Link as={Link} to="/contact-us" className="text-white">
						Contact Us
					</Nav.Link>
				</Nav>
				<Nav>
					<Nav.Link as={Link} className="text-center px-0 pt-3 py-lg-1">
						<Button
							as={Link}
							to="/contact-us"
							className=" btn-outline-light cta-btn-gtm "
						>
							Book a discovery meeting
						</Button>
					</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default Navigation;
