import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";
import {
	FaFacebookSquare,
	FaInstagram,
	FaLinkedin,
	FaTwitter,
	FaYoutube,
} from "react-icons/fa";
import { MdLocationOn, MdPhone, MdEmail } from "react-icons/md";
import { StaticImage } from "gatsby-plugin-image";

const FooterNewYork = () => {
	return (
		<footer className="py-5 bg-primary text-white">
			<Container>
				<Row className="text-center align-items-lg-center">
					<Col xs={12} lg="auto">
						<StaticImage
							src="../images/logo.svg"
							style={{ maxWidth: "100px", maxHeight: "100px" }}
						/>
					</Col>
					<Col className="py-5 py-lg-3">
						<ul className="text-left list-unstyled text-center text-lg-right">
							<li className="d-md-inline-block mr-md-3 ">
								<Link
									className="text-white "
									to="/written-testimonials"
									style={quickLinks}
								>
									Testimonials
								</Link>
							</li>
							<li className="d-md-inline-block mr-md-3">
								<Link className="text-white" to="/about" style={quickLinks}>
									About Jason
								</Link>
							</li>
							<li className="d-md-inline-block mr-md-3">
								<Link className="text-white" to="/blog" style={quickLinks}>
									Blog
								</Link>
							</li>
							<li className="d-md-inline-block mr-md-3">
								<Link
									className="text-white"
									to="/frequently-asked-questions"
									style={quickLinks}
								>
									FAQs
								</Link>
							</li>
							<li className="d-md-inline-block mr-md-3">
								<Link
									className="text-white"
									to="/policies/privacy-policy"
									style={quickLinks}
								>
									Privacy Policy
								</Link>
							</li>
							<li className="d-md-inline-block ">
								<Link
									className="text-white"
									to="/contact-us"
									style={quickLinks}
								>
									Contact Us
								</Link>
							</li>
						</ul>
					</Col>
				</Row>
				<Row className="pb-5 align-items-center pb-lg-3">
					<Col className="text-center text-lg-right">
						<ul className="pl-0">
							<li className="d-inline-block mr-3">
								<a
									rel="noreferrer"
									target="_blank"
									className="social-icon"
									href="https://www.facebook.com/jasoncornescoaching"
								>
									<FaFacebookSquare style={socialIcons} />
								</a>
							</li>
							<li className="d-inline-block mx-3">
								<a
									rel="noreferrer"
									target="_blank"
									className="social-icon"
									href="https://www.instagram.com/jasoncornesbusinessmentor"
								>
									<FaInstagram style={socialIcons} />
								</a>
							</li>
							<li className="d-inline-block mx-3">
								<a
									rel="noreferrer"
									target="_blank"
									className="social-icon"
									href="https://www.linkedin.com/in/jason-cornes-65580216"
								>
									<FaLinkedin style={socialIcons} />
								</a>
							</li>
							<li className="d-inline-block mx-3">
								<a
									rel="noreferrer"
									target="_blank"
									className="social-icon"
									href="https://twitter.com/JasonCornes"
								>
									<FaTwitter style={socialIcons} />
								</a>
							</li>
							<li className="d-inline-block ml-3 ">
								<a
									rel="noreferrer"
									target="_blank"
									className="social-icon"
									href="https://www.youtube.com/channel/UC1bTe-FmHjedRYeSQWJngPg"
								>
									<FaYoutube className="social-icon" style={socialIcons} />
								</a>
							</li>
						</ul>
					</Col>
				</Row>
				<Row>
					<Col className="text-lg-right">
						<p>
							<MdLocationOn />
							{` `}
							<a
								className="text-white"
								href="https://maps.app.goo.gl/Nhzz9DaFHAqZwBKY8"
							>
								580 Fifth Avenue, Suite 820 , New York, NY 10036
							</a>
							<br className="d-lg-none" />
							<br className="d-lg-none" />
							<MdEmail className="ml-lg-5" />
							{` `}
							<a
								className="text-white email-link"
								href="mailto:jason@jasoncornes.co.uk"
							>
								jason@jasoncornes.co.uk
							</a>
							<br className="d-lg-none" />
							<br className="d-lg-none" />
							<MdPhone className="ml-lg-5" />
							{` `}
							<a className="text-white phone-number" href="tel:2123308067">
								212 330 8067
							</a>
							<br className="d-lg-none" />
							<br className="d-lg-none" />
						</p>
					</Col>
				</Row>
				<hr className="bg-white" />
				<Row className="text-center text-lg-right">
					<Col>
						<a className="text-white" href="https://www.rjm.digital">
							Web design by RJM Digital
						</a>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

const socialIcons = {
	color: "white",
};

const quickLinks = {};

export default FooterNewYork;
