import React from "react";
import { CookieBanner } from "@connorrjm/rjm-cookie-banner";
import Footer from "./footer";
import Navigation from "./navigation";
import { Helmet } from "react-helmet";
import FooterNewYork from "./footer-new-york";

const Layout = ({ children, NY }) => {
	return (
		<>
			<div style={{ height: "66px", width: "100%" }}></div>
			{/* <Helmet>
        {typeof Window !== "undefined" && (
          <script
            src="https://formspree.io/js/formbutton-v1.min.js"
            defer
          ></script>
        )}
        {typeof Window !== "undefined" && (
          <script
            type="text/javascript"
            innerHTML={`  
       window.formbutton=window.formbutton||function(){(formbutton.q=formbutton.q||[]).push(arguments)};
    formbutton("create", {
      action: "https://formspree.io/f/mvodwerz",
       title: "Get In Touch",
       fields: [
          { 
        type: "text", 
        label: "Name:", 
        name: "name",
        required: true,
        placeholder: "Name"
      },
       { 
        type: "email", 
        label: "Email:", 
        name: "_replyto",
        required: true,
        placeholder: "Email Address"
      },
         { 
        type: "text", 
        label: "Tel:", 
        name: "telephone",
        required: true,
        placeholder: "Telephone Number"
      },
     
     {
        type: "text",
        label: "More Info:",
        name: "findme",
        placeholder: "How and where did you find me?",
      },
     
      {
        type: "textarea",
        label: "Message:",
        name: "how can i help you?",
        placeholder: "How can I help you?",
      },
      { type: "submit" }      
    ],
    styles: {
      title: {
        backgroundColor: "#00205C",
      },
      button: {
        backgroundColor: "#00205C",
        zIndex: "1",
      }
    }
   });
    `}
          />
        )}
      </Helmet> */}
			<Navigation />
			<CookieBanner />
			{children}
			{!NY && <Footer />}
			{NY && <FooterNewYork />}
		</>
	);
};

export default Layout;
